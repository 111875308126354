import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { MaterialModule } from 'src/app/material/material.module';
import { ComboVariationDialogComponent } from 'src/app/shared/components/dialogs/combo-variation-dialog/combo-variation-dialog.component';
import { InfoDialogComponent } from 'src/app/shared/components/dialogs/info-dialog/info-dialog.component';
import { ProfileMenuComponent } from 'src/app/shared/components/profile-menu/profile-menu.component';
import { ProfileComponent } from 'src/app/shared/components/profile/profile.component';
import { ShowtimesErrorComponent } from 'src/app/shared/components/showtimes-error/showtimes-error.component';
import { ShowtimesComponent } from 'src/app/shared/components/showtimes/showtimes.component';
import { translateBrowserLoaderFactory } from 'src/app/shared/loader/translate-browser.loader.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUiDialogComponent } from './components/dialogs/block-ui-dialog/block-ui-dialog.component';
import { ImageDialogComponent } from './components/dialogs/image-dialog/image-dialog.component';
import { MovieDialogComponent } from './components/dialogs/movie-dialog/movie-dialog.component';
import { QrDialogComponent } from './components/dialogs/qr-dialog/qr-dialog/qr-dialog.component';
import { SafetyGuidelinesDialogComponent } from './components/dialogs/safety-guidelines-dialog/safety-guidelines-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MovieOperationDatesComponent } from './components/movie-operation-dates/movie-operation-dates.component';
import { MoviesSwiperComponent } from './components/movies-swiper/movies-swiper.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DBoxGuidelinesContentComponent } from './components/safety-guidelines-content/d-box-guidelines-content/d-box-guidelines-content.component';
import { FourDxGuidelinesContentComponent } from './components/safety-guidelines-content/four-dx-guidelines-content/four-dx-guidelines-content.component';
import { PlayplusGuidelineContentComponent } from './components/safety-guidelines-content/playplus-guideline-content/playplus-guideline-content.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SwiperDirectiveDirective } from './directives/swiper-directive.directive';
import { NavComponent } from './components/nav/nav/nav.component';
import { ProfileItemsEmptyComponent } from './components/profile-items-empty/profile-items-empty.component';
import { AddFavCinemaComponent } from './components/add-fav-cinema/add-fav-cinema.component';
import { RegionFiltersComponent } from './components/dialogs/region-filters/region-filters.component';
import { MovieRatingsGuidelinesDialogComponent } from './components/dialogs/movie-ratings-guidelines-dialog/movie-ratings-guidelines-dialog.component';
import { SeatTypeDialogComponent } from './components/dialogs/seat-type-dialog/seat-type-dialog.component';
import { EmbeddedWebComponent } from './components/embedded-web/embedded-web.component';
import { ProductHighlightDialogComponent } from './components/dialogs/product-highlight-dialog/product-highlight-dialog.component';

@NgModule({
    declarations: [
        BlockUiDialogComponent,
        ComboVariationDialogComponent,
        FooterComponent,
        ImageDialogComponent,
        InfoDialogComponent,
        LoadingComponent,
        MovieDialogComponent,
        MoviesSwiperComponent,
        NavbarComponent,
        SnackbarComponent,
        SwiperDirectiveDirective,
        SafetyGuidelinesDialogComponent,
        PlayplusGuidelineContentComponent,
        FourDxGuidelinesContentComponent,
        DBoxGuidelinesContentComponent,
        QrDialogComponent,
        ShowtimesComponent,
        MovieOperationDatesComponent,
        NavComponent,
        ProfileComponent,
        ProfileMenuComponent,
        ShowtimesErrorComponent,
        ProfileItemsEmptyComponent,
        AddFavCinemaComponent,
        RegionFiltersComponent,
        MovieRatingsGuidelinesDialogComponent,
        SeatTypeDialogComponent,
        EmbeddedWebComponent,
        ProductHighlightDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        MatProgressSpinnerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState],
            },
        }),
        QRCodeModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        FooterComponent,
        LoadingComponent,
        MoviesSwiperComponent,
        NavbarComponent,
        InfoDialogComponent,
        TranslateModule,
        ShowtimesComponent,
        MovieOperationDatesComponent,
        NavComponent,
        ProfileComponent,
        ProfileMenuComponent,
        QRCodeModule,
        ShowtimesErrorComponent,
        ProfileItemsEmptyComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
