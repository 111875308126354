import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MovieDetails } from 'src/app/core/models/movie-listing/movie-details';
import { CinemaListingLocation } from 'src/app/core/models/showtimes-by-cinemas/cinema-listing-location';

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    constructor(
        private router: Router,
        private metaService: Meta,
        private titleService: Title,
    ) { }

    setShowtimesByMovieMetaTag(films: MovieDetails[]) {
        const movieTitles = films.map((film) => film.title).join(';');
        this.metaService.updateTag({ name: 'description', content: movieTitles });
        this.metaService.updateTag({ name: 'keywords', content: movieTitles });
    }

    setShowtimesByCinemaMetaTag(cinemas: CinemaListingLocation[]) {
        const cinemaNames = cinemas.map((cinema) => cinema.name).join(';');
        this.metaService.updateTag({ name: 'description', content: cinemaNames });
        this.metaService.updateTag({ name: 'keywords', content: cinemaNames });
    }

    resetMetaTag(routeTitle?: string) {
        const gscLogo = 'https://cdn4.gsc.com.my/WebLITE/Applications/WCM/catimages/FB_Share_Image/Generic_Image.jpg';
        const gscUrl = 'https://www.gsc.com.my/';
        const gscTitle = routeTitle ?? 'Golden Screen Cinemas | Malaysia\'s Leading Cinema Online';
        const gscDescription = 'Golden Screen Cinemas (GSC) offers a wide range of movies, state-of-the-art facilities, and immersive cinematic experiences. Discover the latest blockbusters, book tickets, and indulge in premium amenities at GSC theaters.';
        const gscKeywords = 'Golden Screen Cinemas, GSC, movies, cinema, theater, film, ticket booking, movie experience, tgv, mbo, lfs, ppb, cinema online';

        this.titleService.setTitle(gscTitle);
        this.metaService.updateTag({ name: 'description', content: gscDescription });
        this.metaService.updateTag({ name: 'image', content: gscLogo });
        this.metaService.updateTag({ name: 'keywords', content: gscKeywords });
        this.metaService.updateTag({ name: 'twitter:url', content: gscUrl });
        this.metaService.updateTag({ name: 'twitter:site_name', content: gscTitle });
        this.metaService.updateTag({ name: 'twitter:title', content: gscTitle });
        this.metaService.updateTag({ name: 'twitter:description', content: gscDescription });
        this.metaService.updateTag({ name: 'twitter:image', content: gscLogo });
        this.metaService.updateTag({ property: 'og:url', content: gscUrl });
        this.metaService.updateTag({ property: 'og:site_name', content: gscTitle });
        this.metaService.updateTag({ property: 'og:title', content: gscTitle });
        this.metaService.updateTag({ property: 'og:description', content: gscDescription });
        this.metaService.updateTag({ property: 'og:image', content: gscLogo });
    }
}
