/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/services/storage.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService,
    ) { }

    ascentisUrl = environment.ascentisApiServer;
    proxyUrl = environment.proxyUrl;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let params = new HttpParams({ fromString: req.params.toString() });
        let { headers } = req;

        if (req.url.indexOf(this.proxyUrl) === -1) {
            return next.handle(req);
        }

        if (params.get('noToken')) {
            params = params.delete('noToken');
            req = req.clone({
                params,
                headers,
            });

            return next.handle(req);
        }

        const { accessToken } = this.storageService;
        if (!accessToken) {
            return next.handle(req);
        }

        headers = headers.set('Authorization', `Bearer ${accessToken}`).set('X-ApiKey', `${environment.clientID}:${environment.clientSecret}`);

        req = req.clone({
            params,
            headers,
        });

        return next.handle(req);
    }
}
