import { RouteType } from '../enums/auth.enum';

export const AurumEcomboSignature = 'KJKJKLJIOUIUOIOIUOIUTYEFFCZXCCVBBNNMKJHGFDSA';
export const AurumEcomboVer = '1.0.0.1';

// Ascentis API Credentials
export const ASCENTIS_CRM_GRANT_TYPE = 'client_credentials';
export const ASCENTIS_CRM_SCOPE = 'crmapi';
export const ASCENTIS_CRM_CLIENT_ID = 'GSC_MOBILE-GSC\\testuser';
export const ASCENTIS_CRM_CLINT_SECRET = 'PtkK28uAmeQ8uanz';
export const ASCENTIS_CRM_ENQUIRY_CODE = 'MOBILE-GSC';
export const ASCENTIS_CRM_OUTLET_CODE = 'App';
export const ASCENTIS_CRM_POS_ID = 'MobileApp';
export const ASCENTIS_CRM_CASHIER_ID = 'MobileApp';
export const ASCENTIS_CRM_IGNORE_CNN_CHECKING = false;
export const ASCENTIS_SOAP_ACTION = 'https://MatrixAPIs/JSONCommand2';
export const ASCENTIS_DEFAULT_API_ROUTE = 'api/Member/';
export const ASCENTIS_VOUCHER_API_ROUTE = 'api/Voucher/';
export const GET_MEMBER_PROFILE = 'GetMemberProfile';
export const GET_MY_CARD_DETAILS = 'GetMyCardDetails';
export const GET_MY_VOUCHERS = 'GetMyVouchers';
export const GET_MY_TRANSACTION_LIST = 'GetCRMTransactionHistory';
export const CHECK_UNIQUE_INFORMATION = 'CheckUniqueInformation';
export const SIGN_IN = 'SignIn';
export const SIGN_UP = 'SignUp';
export const SEND_OTP = 'SendOTP';
export const VALIDATE_OTP = 'ValidateOTP';
export const UPDATE_MEMBER_PROFILE = 'UpdateMemberProfile';
export const UPDATE_PASSWORD = 'UpdatePassword';
export const RESET_PASSWORD = 'ResetPassword';
export const VOUCHER_ISSUANCE_WITH_POINTS = 'VoucherIssuanceWithPoints';
export const DELETE_USER_ACCOUNT = 'DeleteUserAccount';
export const GET_VOUCHER_TYPE = 'GetVoucherType';
export const GET_TOKEN = 'GetToken';
export const GSC_WEBSITE = 'https://www.gsc.com.my/';
export const ERROR_TITLE = 'Error';
export const INFO_TITLE = 'Info';
export const SALT = 'G$c&inv0ice@P!';
export const SUBMIT_EINVOICE_REQ = 'Submit4Einvoice';
export const EINVOICE_TITLE = 'B. Purchased Info';
export const EINVOICE_FNB_TITLE = 'B. Purchased Info (F&B)';

export enum AscentisOTPStatus {
    EmailEmpty = 5,
    InvalidMobileNo = 149,
    InvalidSMSMaskName = 150,
    InvalidSMSMessage = 151,
    InvalidOption = 152,
    InvalidCharLen = 153,
    InvalidCharLenRange = 154,
    GetOTPFail = 155,
}

export function getOTPRequestBody() {
    return {
        OTPType: 'SMS',
        SMSMaskName: 'Matrix',
        EmailTemplate: 'Email OTP',
        Options: 'X',
        CharLen: 4,
    };
}

export function getOTPValidationBody() {
    return {
        OTPType: 'SMS',
        IgnoreCase: false,
        TimeIntervalType: 'Minute',
        TimeIntervalValue: 3,
    };
}

export function getRegisterRequestBody() {
    return {
        MembershipTypeCode: 'GSCRewards',
        TierCode: 'Member',
        MembershipStatusCode: 'ACTIVE',
        RegistrationDate: '',
        PickPreloadCard: false,
        CardNo: '',
        MailingLists: [
            {
                Name: 'GSC Rewards',
            },
        ],
    };
}

export const OTP_MESSAGE = [
    {
        type: RouteType.forget_password,
        message: 'RM0 GSC: Need a password reset? Use OTP code: [OTP]. We’ve got your back!',
    },
    {
        type: RouteType.sign_up,
        message: 'RM0 GSC: Welcome! Your registration OTP code: [OTP] is here. Expires in 3 mins. See you at the movies!',
    },
    {
        type: RouteType.first_time,
        message: 'Welcome! Use GSC OTP code: [OTP]. Remember, it lasts only 3 mins. Stay secure!',
    },
];

export const PAGE_COUNT = 20;
export const GET_TEN = 10;
export const GET_THIRTY = 30;
export const GET_NINETY_NINE = 99;
export const FIRST_PAGE = 1;
export const FULL_COUNT = 9999;
export const DEFAULT_TIMEOUT_VALUE = 30000;
export const MY_TICKET_TIMEOUT = 60000;
export const COULD_NOT_PROCESSED = ' could not be processed right now.';
export const EMPTY_SEAT = 'Seatings';
export const EMPTY_TICKET_TYPE = 'Ticket type confirmation screen';
export const EMPTY_ECOMBO = 'E-Combo selection screen';
export const EMPTY_UPSELLING = 'Upsell selection screen';
export const EMAIL_REGEX = '[A-Za-z0-9._]+@[A-Za-z]+\\.[A-Za-z0-9._]+$';
export const NAME_REGEX = '[A-Za-z0-9\\s]+$';
export const INITSALES_DATE = 'YYYY-MM-DD HH:mm:ss';
export const RESPONSE_TYPE = 'text' as 'json';
export const SUCCESS_STATUS_CODE = '1';
export const FOURD = '4D';
export const PLAY = 'PLAY';
export const OTP = 'OTP';

export const PAGE_COUNT_REDEEMED_VOUCHERS = 'PageCount_RedeemedVouchers';
export const OTP_TIMER = 180;

export const ASCNEDING_ORDER = 'ASC';
export const DESCENDING_ORDER = 'DESC';

export const FOOTER_DOWNLOAD = [
    // apple
    {
        source: '../../../assets/img/icons/apple_default_icon@2x.png',
        default: '../../../assets/img/icons/apple_default_icon@2x.png',
        hover: '../../../assets/img/icons/apple_hover_icon@2x.png',
        link: 'https://apps.apple.com/my/app/golden-screen-cinemas/id413024972',
    },
    // android
    {
        source: '../../../assets/img/icons/android_default_icon@2x.png',
        default: '../../../assets/img/icons/android_default_icon@2x.png',
        hover: '../../../assets/img/icons/android_hover_icon@2x.png',
        link: 'https://play.google.com/store/apps/details?id=com.gscandroid.yk&hl=en&gl=US&pli=1',
    },
    // huawei
    {
        source: '../../../assets/img/icons/huawei_default_icon@2x.png',
        default: '../../../assets/img/icons/huawei_default_icon@2x.png',
        hover: '../../../assets/img/icons/huawei_hover_icon@2x.png',
        link: 'https://appgallery.huawei.com/#/app/C100230541',
    },
];

export const FOOTER_FOLLOW_US = [
    // facebook
    {
        source: '../../../assets/img/icons/fb_default_icon@2x.png',
        default: '../../../assets/img/icons/fb_default_icon@2x.png',
        hover: '../../../assets/img/icons/fb_hover_icon@2x.png',
        link: 'https://www.facebook.com/GSCinemas/',
    },
    // insta
    {
        source: '../../../assets/img/icons/insta_default_icon@2x.png',
        default: '../../../assets/img/icons/insta_default_icon@2x.png',
        hover: '../../../assets/img/icons/instagram_hover_icon@2x.png',
        link: 'https://www.instagram.com/gscinemas/',
    },
    // twitter
    {
        source: '../../../assets/img/icons/twitter_default_icon@2x.png',
        default: '../../../assets/img/icons/twitter_default_icon@2x.png',
        hover: '../../../assets/img/icons/twitter_hover_icon@2x.png',
        link: 'https://twitter.com/GSCinemas',
    },
    // tiktok
    {
        source: '../../../assets/img/icons/tiktok_default_icon@2x.png',
        default: '../../../assets/img/icons/tiktok_default_icon@2x.png',
        hover: '../../../assets/img/icons/tiktok_hover_icon@2x.png',
        link: 'https://www.tiktok.com/@gscinemas',
    },
    // youtube
    {
        source: '../../../assets/img/icons/youtube_default_icon@2x.png',
        default: '../../../assets/img/icons/youtube_default_icon@2x.png',
        hover: '../../../assets/img/icons/youtube_hover_icon@2x.png',
        link: 'https://www.youtube.com/c/gscinemas',
    },
];
