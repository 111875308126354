import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpResponseInterceptor } from 'src/app/shared/interceptors/http-response.interceptor';
import { DEFAULT_TIMEOUT_VALUE } from 'src/app/core/constants/api.constants';
import { AccessTokenInterceptor } from './access-token.interceptor';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './time-out.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: DEFAULT_TIMEOUT_VALUE },
];
