/* eslint-disable import/no-extraneous-dependencies */
import { DOCUMENT } from '@angular/common';
import { ComponentRef, Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsInitializer, GoogleAnalyticsRouterInitializer, GoogleAnalyticsService, GtagFn, IGoogleAnalyticsSettings, NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN } from 'ngx-google-analytics';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticService {
    private isInitialized: boolean | undefined;

    public constructor(
        @Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) private googleAnalyticsSettingsToken: IGoogleAnalyticsSettings,
        @Inject(NGX_GTAG_FN) private googleTagFunction: GtagFn,
        @Inject(DOCUMENT) private document: Document,
        public googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }

    public initializeGoogleAnalytics(bootstrapComponent: ComponentRef<unknown>) {
        if (!this.isInitialized) {
            Promise.all([
                GoogleAnalyticsInitializer(this.googleAnalyticsSettingsToken, this.googleTagFunction, document)(),
                GoogleAnalyticsRouterInitializer({}, this.googleAnalyticsService)(bootstrapComponent),
            ]).then(() => {
                this.isInitialized = true;
            }).catch(() => {
                this.isInitialized = false;
            });
        }
    }
}
