export enum MovieTicketType {
    TicketWithoutEcombo,
    TicketWithEcomboOnly,
    TicketWithBundleCodeOnly,
    TicketWithBundleCodeAndEcombo,
}

export enum UpsellingType {
    NoUpselling,
    HasUpselling,

}
