<div class="flex min-h-screen w-full">
    <div class="basis-full md:basis-1/2 {{isOtherRoute ? 'mt-[1.688rem]' : 'mt-[1.375rem]'}} px-5 lg:px-10 xl:pl-20 xl:pr-[9.813rem] flex flex-col">
        <a title="home" class="{{isOtherRoute ? 'hidden' : 'block'}} gsc-icon-8 w-[6.813rem] bg-gsc-icon-app mb-7 md:m-0" [href]="[GSC_WEBSITE_URL]"></a>
        <div class="grow">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="hidden md:block basis-1/2">
        <div class="h-full w-full gsc-icon-0 bg-gsc-login-graphic bg-white/20 bg-blend-overlay"></div>
    </div>
</div>
