<footer class="bg-cover bg-gsc-footer-base-mobile lg:bg-gsc-footer-base w-full asbolute bottom-0">
    <div class="gsc-container">
        <div
            class="grid grid-cols-1 md:grid-cols-2 gap-y-8 md:gap-y-16 md:gap-x-12 border-b border-gsc-grey-light/50 px-5 md:px-20 pt-6 pb-4">
            <div class="font-montMedium text-sm md:text-base tracking-wider">
                <div class="flex flex-col gap-y-5 font-montMedium">
                    <a *ngFor="let externalLink of externalLinks" [href]="externalLink.url" target="_blank"
                        class="hover:text-gsc-main-yellow" translate>{{ externalLink.name }}</a>
                </div>
            </div>

            <div class="flex my-8 md:my-0">
                <ul class="flex justify-items-end flex-col gap-y-3">
                    <li>
                        <p class="font-montMedium md:text-lg tracking-wider" translate>Golden Screen Cinemas</p>
                        <p class="font-poppinsRegular text-sm text-gsc-grey-lighter footer-item-style" translate>footer.registrationNo</p>
                    </li>
                    <li class="footer-item-style">
                        <p translate>footer.address</p>
                        <p translate>footer.addressArea</p>
                        <p translate>footer.addressState</p>
                    </li>
                    <li class="footer-item-style">
                        <div class="flex flex-row">
                            <p class="basis-1/5" translate>Tel</p>
                            <!-- TODO: Call-able phone link -->
                            <p class="basis-4/5 text-gsc-main-yellow cursor-pointer underline decoration-gsc-main-yellow"
                                translate>footer.tel</p>
                        </div>
                        <div class="flex flex-row">
                            <p class="basis-1/5" translate>Email</p>
                            <p class="basis-4/5 text-gsc-main-yellow cursor-pointer underline decoration-gsc-main-yellow"
                                (click)="goToMail()" translate>footer.email</p>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="grid grid-cols-1 md:flex gap-3 items-center">
                <p class="font-montBold tracking-wider md:text-lg" translate>Download Our App</p>
                <div class="flex flex-row gap-x-2">
                    <div class="footer-icon" *ngFor="let itm of footerDownload;">
                        <a class="rounded-full footer-icon"
                        [href]="itm.link" target="_blank">
                        <img [src]="itm.source" (mouseover)="itm.source = itm.hover" (mouseleave)="itm.source = itm.default"/>
                    </a>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 md:flex gap-3 items-center">
                <p class="font-montBold tracking-wider md:text-lg" translate>Follow Us</p>
                <div class="flex flex-row gap-x-2">
                    <div *ngFor="let itm of footerFollowUs" class="footer-icon">
                        <a class="rounded-full footer-icon" [href]="itm.link"
                        target="_blank">
                        <img [src]="itm.source" (mouseover)="itm.source = itm.hover" (mouseleave)="itm.source = itm.default"/>
                    </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-xs md:text-sm text-gsc-grey-lighter px-5 md:px-20 py-4">
            <span translate>footer.copyright1</span>
            <span>{{currentDate | date: 'yyyy'}}</span>
            <span translate>footer.copyright2</span>
        </div>
    </div>
</footer>
