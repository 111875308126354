import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CINEMA_LISTING_URL, E_INVOICE_URL, FAST_TICKET_URL, HALL_BOOKING_LISTING_URL, SHOWTIME_BY_MOVIES_URL } from 'src/app/core/constants/app.constants';
import { MenuItem } from 'src/app/core/models/item/menu-item';
import { AccountService } from '../api/auth/account.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private menuItems: MenuItem[] = [
        { name: 'Showtime by Movies', url: `/${SHOWTIME_BY_MOVIES_URL}` },
        { name: 'Showtime by Cinemas', url: `/${CINEMA_LISTING_URL}` },
        { name: 'Hall Booking', url: `/${HALL_BOOKING_LISTING_URL}` },
        { name: 'FasTicket', url: `/${FAST_TICKET_URL}` },
    ];

    constructor(private router: Router) {}

    getMenuItems(): MenuItem[] {
        return this.menuItems;
    }

    isActiveMenu(menuItem: MenuItem): boolean {
        const currentRoute = this.router.url;
        if (currentRoute.indexOf(menuItem.url) > -1) {
            return true;
        }

        return false;
    }
}
