import { Component, OnInit } from '@angular/core';
import { FOOTER_DOWNLOAD, FOOTER_FOLLOW_US } from 'src/app/core/constants/api.constants';
import { FooterExternalLinks } from 'src/app/core/enums/footer-external-url.enum';
import { FooterItem } from 'src/app/core/models/item/menu-item';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    externalLinks: FooterItem[] = [];
    GSCEmail = 'cs@gsc.com.my';
    footerEnum: typeof FooterExternalLinks = FooterExternalLinks;
    currentDate = new Date();
    footerDownload = FOOTER_DOWNLOAD;
    footerFollowUs = FOOTER_FOLLOW_US;

    ngOnInit(): void {
        this.constructFooter();
    }

    private constructFooter(): void {
        this.externalLinks = [
            { name: 'FAQ', url: 'https://www.gsc.com.my/faqs' },
            { name: 'Privacy Policy', url: 'https://www.gsc.com.my/privacy-policy' },
            { name: 'ABAC Policy', url: 'https://www.gsc.com.my/abac-policy' },
            { name: 'Whistleblowing Policy', url: 'https://www.gsc.com.my/whistleblowing-policy' },
        ];
    }

    goToMail() {
        window.open(`mailto:${this.GSCEmail}`);
    }
}
