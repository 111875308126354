/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { ASCENTIS_DEFAULT_API_ROUTE, ASCNEDING_ORDER, AscentisOTPStatus, DESCENDING_ORDER, GET_MY_VOUCHERS, GET_VOUCHER_TYPE, SEND_OTP, SIGN_IN, SIGN_UP, UPDATE_MEMBER_PROFILE, VALIDATE_OTP, getRegisterRequestBody } from 'src/app/core/constants/api.constants';
// import { AscentisAuth } from 'src/app/core/models/account/authentication';
// import { AscentisAuthModel } from 'src/app/core/models/account/authentication.model';
import { IUserLoginForm } from 'src/app/core/models/account/login';
import { AscentisResponseBase } from 'src/app/core/models/ascentis-base/ascentis-response-base';
import { VoucherList } from 'src/app/core/models/voucher/voucher-list';
import { RegisterUserInfoBody } from 'src/app/core/models/register/register-user-info-body';
import { UpdateProfile } from 'src/app/core/models/update-profile/update-profile';
import { StorageService } from 'src/app/core/services/storage.service';
import { environment } from 'src/environments/environment';
import { MyVoucher } from 'src/app/core/models/voucher/my-voucher';
import { AscentisWrapperBase } from 'src/app/core/models/ascentis-base/ascentis-wrapper-base';
import { SignIn } from 'src/app/core/models/account/sign-in';
import { SignUp } from 'src/app/core/models/account/sign-up';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private authRoute = environment.ascentisTokenApiServer;
    private route: string = environment.ascentisApiServer + ASCENTIS_DEFAULT_API_ROUTE;
    private proxyRoute = `${environment.proxyUrl}/`;
    private proxyToken = environment.proxyToken;

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
    ) {}

    getAccessToken(): Observable<string> {
        return of(this.storageService.accessToken);
    }

    login(userLoginForm: IUserLoginForm, isReset?: boolean): Observable<AscentisWrapperBase<SignIn>> {
        const endpoint = this.proxyRoute + SIGN_IN;
        const body = {
            username: isReset ? userLoginForm.mobileNumber : userLoginForm.dialCode + userLoginForm.mobileNumber,
            password: userLoginForm.password,
        };

        return this.http.post(endpoint, body).pipe(map((res: any) => res));
    }

    logout(): Observable<any> {
        return of('').pipe(
            tap(() => {
                this.storageService.clearUserInfo();
            }),
        );
    }

    getUserInitial(): string {
        const username = this.storageService.userName;

        if (!username) {
            return '';
        }

        const names = username.split(' ').map((name: string) => name.slice(0, 1)).join('');
        return names.slice(0, 2).toUpperCase();
    }

    isAuthenticated = () => !!this.storageService.userMembershipInfo;

    registerMember(info: RegisterUserInfoBody, otp: string):Observable<AscentisWrapperBase<SignUp>> {
        const endpoint = this.proxyRoute + SIGN_UP;
        info.OtpCode = otp;
        if (this.storageService.otpToken) {
            info.OtpToken = this.storageService.otpToken;
        }
        const body = {
            ...getRegisterRequestBody(),
            ...info,
        };

        return this.http.post(endpoint, body).pipe(map((res:any) => res));
    }

    getOTP(mobileNo:string): Observable<
    AscentisWrapperBase<AscentisResponseBase<AscentisOTPStatus>>> {
        const endpoint = this.proxyRoute + SEND_OTP;
        const body = {
            mobileNo,
        };

        return this.http.post(endpoint, body).pipe(map((res:any) => res));
    }

    validateOTP(mobileNo: string, otp: string): Observable<AscentisWrapperBase<
    AscentisResponseBase<AscentisOTPStatus>>> {
        const endpoint = this.proxyRoute + VALIDATE_OTP;
        const body = {
            mobileNo,
            otpCode: otp,
        };

        return this.http.post(endpoint, body).pipe(map((res:any) => res));
    }

    updateProfile(reqBody?: any): Observable<AscentisWrapperBase<UpdateProfile>> {
        const endpoint = this.proxyRoute + UPDATE_MEMBER_PROFILE;
        const body = {
            ...reqBody,
        };

        return this.http.post(endpoint, body).pipe(map((res:any) => res));
    }

    getRewardCenterListing(showAll: boolean, pageNo: number, pageCount: number, isRedeemable?: boolean):Observable<
    AscentisWrapperBase<VoucherList>> {
        const endpoint = this.proxyRoute + GET_VOUCHER_TYPE;

        const params = new HttpParams(
            {
                fromObject: { 
                   OnlyOutletVoucherType: false,
                   RetrieveTnC: true,
                   RetrievePromoMsgBy_VoucherTypeCodes: '',
                   RetrievePromoMsgBy_PrefixVoucherTypeCode: '',
                   RetrieveParticipatingOutletInformation: true,
                   RetrieveBase64ImageString: false,
                   FilterBy_VoucherTypeName: '',
                   FilterBy_VoucherTypeType: '',
                   FilterBy_IsRedeemable: isRedeemable ?? true,
                   FilterBy_PointUsageType: '',
                   FilterBy_Category: '',
                   FilterBy_SubCategory: '',
                   FilterBy_IncludeExpired: false,
                   FilterBy_ParticipatingEntities: '',
                   FilterBy_ParticipatingOutlets: '',
                   FilterBy_isPublished: true,
                   SortOrder: '',
                   SortByVoucherTypeCode: false,
                   SortByVoucherTypeName: false,
                   SortByVoucherTypeType: false,
                   SortByVoucherTypeStartDate: false,
                   SortByVoucherTypeEndDate: false,
                   FilterBy_VoucherTypeCode: '',
                   PageNumber: pageNo, 
                   PageCount: pageCount,
                },
            },
        );

        return this.http.get(endpoint, { params }).pipe(map((val:any) => val));
    }

    getMyRewardsListing(
        isActive: boolean,
        activePageNo: number,
        activePageCount: number,
        expiredPageNo?: number,
        expiredPageCount?: number,
        redeemedPageNo?: number,
        redeemedPageCount?: number,
    ): Observable<AscentisWrapperBase<MyVoucher>> {
        const endpoint = this.proxyRoute + GET_MY_VOUCHERS;

        const params = new HttpParams(
            {
                fromObject: {
                    SortOrder: !isActive ? DESCENDING_ORDER : ASCNEDING_ORDER,
                    RetrieveActiveVouchers: !!isActive,
                    PageNumber_ActiveVouchers: activePageNo || 0,
                    PageCount_ActiveVouchers: activePageCount || 0,
                    RetrieveExpiredVouchers: !isActive,
                    PageNumber_ExpiredVouchers: expiredPageNo || 0,
                    PageCount_ExpiredVouchers: expiredPageCount || 0,
                    RetrieveRedeemedVouchers: !isActive,
                    PageNumber_RedeemedVouchers: redeemedPageNo || 0,
                    PageCount_RedeemedVouchers: redeemedPageCount || 0,
                },
            },
        );

        return this.http.get(endpoint, { params }).pipe(map((val:any) => val));
    }
}
