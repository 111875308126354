import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GSC_WEBSITE } from 'src/app/core/constants/api.constants';
import { FIRST_TIME_LOGIN_URL, FORGOT_PASSWORD_URL, SETUP_PASSWORD_URL } from 'src/app/core/constants/app.constants';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent {
    isOtherRoute = false;
    GSC_WEBSITE_URL = `${GSC_WEBSITE}`;

    constructor(
        private route: Router,
    ) {
        this.route.events.subscribe(() => {
            this.isOtherRoute = !!(route.url.includes(FORGOT_PASSWORD_URL)
            || route.url.includes(FIRST_TIME_LOGIN_URL) || route.url.includes(SETUP_PASSWORD_URL));
        });
    }
}
