export enum PurchaseEntryPoint {
    ByMovie = 'ByMovie',
    ByCinema = 'ByCinema',
    FasTicket = 'FasTicket',
    HallBooking = 'HallBooking',
}

export enum PasswordType {
    CURRENT_PASSWORD = 'currentPassword',
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirmPassword',
}

export enum SeatMappingCategory {
    TYPE = 'TYPE',
    STATUS = 'STATUS',
}

export enum SafetyGuidelinesTitleDisplay {
    PLAYPLUS = 'Play Plus Safety Guidelines',
    '4DX' = '4DX Safety Guidelines',
    MX4D = 'MX4D Safety Guidelines',
    EXTREME4DX = 'Extreme 4DX Safety Guidelines',
    DBOX = 'DBOX Safety Guidelines',
}

export enum SeatSelectionQueryParams {
    PARENT_ID = 'parentID',
    OPRN_DATE = 'oprndate',
    LOCATION_ID = 'locationID',
    CHILD_CODE = 'childCode',
    SHOW_ID = 'showID',
    HALL_GROUP = 'hallGroup',
}

export enum CinemaType {
    AURUM = 'AURUM',
    VELVET = 'VELVET',
}

export enum RewardsCategory {
    ALL = 'ALL',
    TICKETS = 'TICKETS',
    FNB = 'FNB',
    OTHERS = 'OTHERS',
}
