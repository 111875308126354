/* eslint-disable eqeqeq */
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { GSC_WEBSITE } from 'src/app/core/constants/api.constants';
import { LOGIN_URL, MY_TICKET_URL, PROFILE_URL } from 'src/app/core/constants/app.constants';
import { MenuItem } from 'src/app/core/models/item/menu-item';
import { AccountService } from 'src/app/core/services/api/auth/account.service';
import { DataService } from 'src/app/core/services/data.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { MenuService } from 'src/app/core/services/ui/menu.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnChanges {
    @ViewChild('me') menuTrigger?: MatMenuTrigger;

    LOGIN_URL = `/${LOGIN_URL}`;
    PROFILE_URL = PROFILE_URL;
    MY_TICKET_URL = MY_TICKET_URL;
    GSC_WEBSITE_URL = `${GSC_WEBSITE}`;

    @Input() sidenavRef!: MatSidenav;
    menu: MenuItem[] = [];
    userName = '';
    openNav = false;
    @Output() someEvent = new EventEmitter();
    @Input() navStatus = false;
    isLogin = false;

    constructor(
        public menuService: MenuService,
        public accountService: AccountService,
        private storageService: StorageService,
        private dataService: DataService,
        private router: Router,
    ) { 
        this.isLogin = this.accountService.isAuthenticated() ?? false;
        this.getMenu();

        this.router.events.subscribe((e) => {
            this.isLogin = this.accountService.isAuthenticated() ?? false;
            this.getMenu();
        })
    }

    @HostListener('document:click', ['$event'])
    handleOutsideClick() {
        this.menuTrigger?.closeMenu();
    }

    ngOnChanges() {
        this.openNav = this.navStatus;
    }

    ngOnInit(): void {
        this.getMenu();
        this.userName = this.storageService.userName;
        this.subscribeRefreshProfileInfo();
    }

    getMenu() {
        this.menu = this.menuService.getMenuItems();
    }

    onLogoutClick(): void {
        this.accountService.logout().subscribe({
            next: () => {
                this.router.navigate(['/']);
            },
        });
    }

    closeMobileNav(): void {
        this.someEvent.emit(false);
    }

    onToggleNav() {
        this.openNav = !this.openNav;
        this.someEvent.emit(this.openNav);
    }

    private subscribeRefreshProfileInfo(): void {
        this.dataService.getRefreshProfileFlag().subscribe((isRefresh) => {
            if (isRefresh) {
                this.userName = this.storageService.userName;
            }
        });
    }
}
