import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN_URL } from 'src/app/core/constants/app.constants';
import { MenuItem } from 'src/app/core/models/item/menu-item';
import { AccountService } from 'src/app/core/services/api/auth/account.service';
import { MenuService } from 'src/app/core/services/ui/menu.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
    @Output() itemClicked = new EventEmitter<void>();
    LOGIN_URL = LOGIN_URL;
    sidenavMenu: MenuItem[] = [];
    isLogin = false;

    constructor(
        public menuService: MenuService,
        public accountService: AccountService,
        private router: Router,
    ) {
        this.isLogin = this.accountService.isAuthenticated() ?? false;
        this.getMenu();

        this.router.events.subscribe((e) => {
            this.isLogin = this.accountService.isAuthenticated() ?? false;
            this.getMenu();
        });
    }

    ngOnInit(): void {
       this.getMenu();
    }

    getMenu() {
        this.sidenavMenu = this.menuService.getMenuItems();
    }

    onLogoutClick(): void {
        this.accountService.logout().subscribe({
            next: () => {
                this.router.navigate(['/']);
            },
        });
    }
}
