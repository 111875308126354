/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LOGIN_URL } from 'src/app/core/constants/app.constants';
import { AccountService } from 'src/app/core/services/api/auth/account.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private accountService: AccountService,
        private storageService: StorageService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(
                (err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.storageService.clearUserInfo();
                            this.router.navigate([LOGIN_URL]);
                            return NEVER;
                            // return this.handle401Error(req, next);
                        }
                    }

                    return throwError(
                        () => err.error,
                    );
                },
            ),
        );
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.accountService.getAccessToken().pipe(
            switchMap(
                (response) => {
                    const newAccessToken = response;
                    return next.handle(this.addToken(req, newAccessToken));
                },
            ),
            catchError(
                () => {
                    this.router.navigate([`/${LOGIN_URL}`]);
                    return throwError(() => new Error());
                },
            ),
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
