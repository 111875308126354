export enum DialCode {
    MY = '+6',
    SG = '+65',
    BN = '+673',
}

export const DIAL_CODE_OPTIONS = {
    [DialCode.MY]: { cssClass: 'bg-gsc-icon-malaysia', countryCode: 'MY' },
    [DialCode.SG]: { cssClass: 'bg-gsc-icon-singapore', countryCode: 'SG' },
    [DialCode.BN]: { cssClass: 'bg-gsc-icon-brunei', countryCode: 'BN' },
};

export enum RouteType {
    forget_password = 'FORGET_PASSWORD',
    sign_up = 'SIGN_UP',
    first_time = 'FIRST_TIME',
}
