<div class="flex items-center justify-center w-[100%] absolute z-[1000] bg-black pt-[0.875rem]">
    <div class="block" (click)="itemClicked.emit()">
        <a mat-list-item [routerLink]="menuItem.url" class="flex justify-center font-montRegular text-[14px] py-[1rem]"
        [class.active]="menuService.isActiveMenu(menuItem)"
        *ngFor="let menuItem of sidenavMenu">{{menuItem.name}}</a>
        <div [class.hidden]="!accountService.isAuthenticated()" class="mx-auto flex items-center gap-x-2 cursor-pointer bg-white w-fit auth-btn" (click)="onLogoutClick()">
            <div class="gsc-icon-sm bg-gsc-icon-logout"></div>
            <button class="text-center text-[#FF0000]" translate>Log Out</button>
        </div>
    </div>
</div>
