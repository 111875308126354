import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from 'src/app/layouts/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from 'src/app/layouts/content-layout/content-layout.component';
import { environment } from 'src/environments/environment';
import { EmbeddedWebComponent } from './shared/components/embedded-web/embedded-web.component';

const prodRoutes: Routes = [
    { path: '', redirectTo: 'showtime-by-movies', pathMatch: 'full' },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then((m) => m.AuthLayoutModule),
            },
        ],
    },
    {
        path: '',
        component: ContentLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./layouts/content-layout/content-layout.module')
                    .then((m) => m.ContentLayoutModule),
            },
        ],
    },
    // {
    //     path: 'embedded-web',
    //     component: EmbeddedWebComponent,
    //     data: {
    //         title: 'Embedded Web',
    //     }
    // },
    { path: '**', redirectTo: 'showtime-by-movies' },
];

const devRoutes: Routes = [
    {
        path: 'release-note',
        loadComponent: () => import('./pages/release-note/release-note.component').then(
            (mod) => mod.ReleaseNoteComponent,
        ),
    },
    ...prodRoutes,
];

const currentRoutes: Routes = environment.production ? prodRoutes : devRoutes;

const routerOptions: ExtraOptions = {
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100],
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking',
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(currentRoutes, routerOptions),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule { }
