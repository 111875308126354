import { trigger, state, style, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSmartBannerService } from '@netcreaties/ngx-smart-banner';
import { SMART_APP_BANNER_TITLE, SMART_APP_BANNER_AUTHOR, IOS_APP_STORE_URL, ANDROID_PLAY_STORE_URL } from 'src/app/core/constants/app.constants';
import { AccountService } from 'src/app/core/services/api/auth/account.service';
import { StorageService } from 'src/app/core/services/storage.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const googletag: any;

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('300ms', style({ opacity: 0 })),
            ]),
        ]),
    ],
    providers: [NgxSmartBannerService],
})

export class ContentLayoutComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @ViewChild('smartAppBannerContainer', { read: ViewContainerRef }) viewContainerRef?: ViewContainerRef;

    isInvoice = false;
    isOtp = false;
    isSidenavOpen = false;
    showAds = true;
    isLogin = false;
    isApp = false;

    constructor(
        private route: Router,
        private changeDetector: ChangeDetectorRef,
        private readonly ngxSmartBannerService: NgxSmartBannerService,
        private accountService: AccountService,
        private storageService: StorageService,
        @Inject(PLATFORM_ID) private platform: Record<string, unknown>,
    ) {
        this.route.events.subscribe((event) => {
            this.isLogin = this.accountService.isAuthenticated() ?? false;
            this.isInvoice = this.route.url.includes('my-ticket-invoice');
            this.isOtp = this.route.url.includes('otp');
            this.showAds = !this.isInvoice && !this.isOtp;

            if (this.route.url.includes('mode=mobile')) {
                this.isApp = true;
                this.showAds = false;
            }

            if (event instanceof NavigationEnd && isPlatformBrowser(this.platform)) {
                googletag.cmd.push(() => { googletag.pubads().refresh(); });
            }

        });
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platform)) {
            if (googletag?.apiReady) {
                googletag.destroySlots();
            }
        }
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platform)) {
            this.initAds();
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.showAds) {
            this.initSmartAppBanner();
        }
    }

    dismissNav() {
        this.isSidenavOpen = false;
    }

    onToggleNav(event: boolean) {
        this.isSidenavOpen = event;
    }

    private initAds(): void {
        const gAdsCommentStart = document.createComment(` Google Ads `);
        const gAdsCommentEnd = document.createComment(` End Google Ads `);
        const gAdsScript = document.createElement('script');
        gAdsScript.nonce = 'FBCc7XlLkS0UPwyoTGzYag==';
        gAdsScript.innerHTML = `
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function() {
                googletag.pubads().setForceSafeFrame(true);
                googletag.defineSlot('/28335045/Home_Superbanner', [[728, 90], [320, 50]], 'div-gpt-ad-1671701746735-0')
                    .addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.enableServices();
            });
        `;
        const head = document.getElementsByTagName('head')[0];

        head.insertBefore(gAdsCommentEnd, head.firstChild);
        head.insertBefore(gAdsScript, head.firstChild);
        head.insertBefore(gAdsCommentStart, head.firstChild);

        googletag.cmd.push(
            () => { googletag.display('div-gpt-ad-1671701746735-0'); },
        );
    }

    private initSmartAppBanner(): void {
        if (!this.viewContainerRef) { return; }

        this.ngxSmartBannerService.initialize({
            viewContainerRef: this.viewContainerRef,
            title: SMART_APP_BANNER_TITLE,
            author: SMART_APP_BANNER_AUTHOR,
            icon: {
                ios: '/assets/img/icons/gsc-app-icon.png',
                android: '/assets/img/icons/gsc-app-icon.png',
            },
            buttonUrl: {
                ios: IOS_APP_STORE_URL,
                android: ANDROID_PLAY_STORE_URL,
            },
            hideRating: true,
        });
    }
}
