<div #smartAppBannerContainer></div>
<!-- /28335045/Home_Superbanner -->
<div [class.hidden]="!showAds" class="flex justify-center w-full max-w-full">
    <div id='div-gpt-ad-1671701746735-0' style='min-width: 320px; min-height: 50px;'>
    </div>
</div>
<app-navbar [navStatus]="isSidenavOpen" (someEvent)="onToggleNav($event)" *ngIf="!isInvoice && !isOtp && !isApp"></app-navbar>
<mat-sidenav-container class="relative h-full">
    <mat-sidenav-content class="m-auto">
        <div class="min-h-[50vh]">
            <app-nav [ngClass]="{'toShowNav' : isSidenavOpen}" *ngIf="isSidenavOpen" (itemClicked)="dismissNav()"></app-nav>
            <router-outlet (click)="dismissNav()" [ngClass]="{'toShowNav openMenu' : isSidenavOpen}"></router-outlet>
        </div>
        <app-footer *ngIf="!isInvoice && !isOtp && !isApp"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
