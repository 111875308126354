/* eslint-disable eqeqeq */
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FbPixelService } from 'src/app/core/services/fb-pixel.service';
import { MetaService } from 'src/app/core/services/meta.service';
import { register } from 'swiper/element/bundle';
import { GARoute, GAScreen } from './core/enums/google-analytics-screens.enum';
import { GoogleAnalyticService } from './core/services/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'GoldenScreenCinemas';
    isRendered = false;

    constructor(
        public translate: TranslateService,
        private metaService: MetaService,
        private router: Router,
        private route: ActivatedRoute,
        private fbPixelService: FbPixelService,
        @Inject(PLATFORM_ID) private platform: Record<string, unknown>,
        private analyticsService: GoogleAnalyticService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.analyticsService.googleAnalyticsService.pageView(
                    event.urlAfterRedirects,
                    this.getTitle(event.urlAfterRedirects),
                );

                if (isPlatformBrowser(this.platform)) {
                    const activatedRoute = this.getChild(this.route);
                    activatedRoute.data.subscribe((data) => {
                        if (!data.isChangeMeta) {
                            this.metaService.resetMetaTag();
                        }
                    });
                }
            }
        });

        if (isPlatformBrowser(this.platform)) {
            this.isRendered = true;
        }
    }

    ngOnInit() {
        this.initLanguage();
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platform)) {
            register();
            this.fbPixelService.initialize();
        }
    }

    getTitle(currentRoute: string) {
        const route = currentRoute.substring(1);
        const value = Object.values(GARoute).filter((e) => e == route)[0];
        const key = Object.keys(GARoute)[Object.values(GARoute).indexOf(value)];
        return GAScreen[key as keyof typeof GAScreen];
    }

    private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        }
        return activatedRoute;
    }

    private initLanguage(): void {
        this.translate.addLangs(['en-US']);
        this.translate.setDefaultLang('en-US');
    }
}
