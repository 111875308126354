export const environment = {
    production: true,
    uat: false,
    apiServer: 'https://epaymentapi.gsc.com.my/',
    invoiceServer: 'https://epayment.gsc.com.my/',
    ascentisApiServerDomain: 'https://gsc-api-wrapper.ascentis.com.sg/',
    ascentisApiServer: 'https://gsc-api-wrapper.ascentis.com.sg/',
    ascentisTokenApiServer: 'https://uatcrm.ascentis.com.sg/APIsWrapperIdentity/connect/token',
    pixelId: '1125961400866097',
    gaMid: 'G-9CN66XRBN6',
    originUrl: 'https://epaymentwebapp.gsc.com.my/',
    proxyUrl: 'https://epaymentwebapp.gsc.com.my/api',
    proxyToken: 'https://epaymentwebapp.gsc.com.my/api/token',
    initSalesTransactionVersion: '4.0.0',
    paymentGatewayUrl: 'https://secure2.gsc.com.my/epayapp/pub/emreq/emallpay_req.do',
    clientID: 'GSC_MOBILE-GSC\\GSCMobileGSC',
    clientSecret: 'Lm2Mawq4yN6WVDkPcgSsfF',
};
