export const FooterExternalLinks = {
    apple: 'https://apps.apple.com/my/app/golden-screen-cinemas/id413024972',
    android: 'https://play.google.com/store/apps/details?id=com.gscandroid.yk&hl=en&gl=US&pli=1',
    huawei: 'https://appgallery.huawei.com/#/app/C100230541',
    fb: 'https://www.facebook.com/GSCinemas/',
    insta: 'https://www.instagram.com/gscinemas/',
    twitter: 'https://twitter.com/GSCinemas',
    tiktok: 'https://www.tiktok.com/@gscinemas',
    youtube: 'https://www.youtube.com/c/gscinemas',
};
