import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Injectable({
    providedIn: 'root',
})
export class FbPixelService {
    constructor(
        @Inject(PLATFORM_ID) private platform: Record<string, unknown>,
    ) {}

    /* eslint-disable */
    initialize(): void {
        (function (f: any, b, e, v, n?: any, t?: any, s?: any) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', environment.pixelId);
        fbq('track', 'PageView');
    }

    trackCustomEvent(eventName: string, params: any): void {
        if (isPlatformBrowser(this.platform)) {
            fbq('trackCustom', eventName, params);
        }
    }
}
