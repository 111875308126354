export enum GAScreen {
    Login = 'Login Screen',
    Sign_Up = 'Sign Up Screen',
    Forgot_Password = 'Forgot Password Screen',
    Reset_Password = 'Forget Password - Reset Password Screen',
    First_Time_Login_Reset_Password = 'First Time Login - Reset Password Screen',
    Showtimes_By_Movie = 'Showtimes by Movies Screen',
    Seat_Selection = 'Seat Selection Screen',
    E_Combo = 'E-Combo Screen',
    Aurum_E_Combo = 'Aurum E-Combo Screen',
    Confirm_Ticket_Type = 'Confirm Ticket Type Screen',
    Review_Summary = 'Review Summary Screen',
    Cinema_Listing = 'Cinema Listing Screen',
    Showtimes_By_Cinema = 'Showtimes by Cinema Screen',
    Fast_Ticket = 'Fast Ticket Screen',
    My_Ticket_Invoice = 'My Ticket Invoice Screen',
    Sign_Up_OTP = 'Sign Up OTP Screen',
    Forget_Password_OTP = 'Forget Password OTP Screen',
    First_Time_Login_OTP = 'First Time Login OTP Screen',
    My_Ticket_Listing = 'My Ticket Listing Screen',
    My_GSCoins_Transaction = 'My GSC Coins Transaction Screen',
    Edit_Profile = 'Edit Profile Screen',
    My_Active_Rewards_Listing = 'My Active Rewards Listing Screen',
    My_Past_Rewards_Listing = 'My Past Rewards Listing Screen',
    My_Active_Rewards_Details = 'My Active Rewards Details Screen',
    My_Past_Rewards_Details = 'My Past Rewards Details Screen',
    Rewards_Centre_Listing = 'Rewards Centre Listing Screen',
    Rewards_Centre_Details = 'Rewards Centre Details Screen',
    Referral = 'Referral Screen',
    Favourite_Cinema_Listing = 'Favourite Cinema Listing Screen',
    Message_Centre = 'Message Centre Screen',
    Language = 'Language Listing Screen',
    Change_Password = 'Change Password Screen',
    Delete_Account = 'Delete Account Screen',
    Profile = 'Profile Screen',
    Hall_Booking_Listing = 'Hall Booking Listing Screen',
    Hall_Booking_Cinema = 'Hall Booking Showtimes By Cinema Screen',
    Hall_Booking_Seat_Selection = 'Hall Booking Seat Selection Screen',
    Hall_Booking_Confirm_Ticket_Type = 'Hall Booking Confirm Ticket Type Screen',
    Hall_Booking_E_Combo = 'Hall Booking E-Combo Screen',
    Hall_Booking_Aurum_E_Combo = 'Hall Booking Aurum E-Combo Screen',
    Hall_Booking_Review_Summary = 'Hall Booking Review Summary Screen',
    E_INVOICE = 'E-Invoice Screen',
}

export enum GARoute {
    Login = 'login',
    Sign_Up = 'sign-up',
    Forgot_Password = 'forgot-password',
    Reset_Password = 'forgot-password/setup-password',
    First_Time_Login_Reset_Password = 'first-time-login/setup-password',
    Showtimes_By_Movie = 'showtime-by-movies',
    Seat_Selection = 'seat-selection',
    E_Combo = 'e-combo',
    Aurum_E_Combo = 'aurum-e-combo',
    Confirm_Ticket_Type = 'confirm-ticket-type',
    Review_Summary = 'review-summary',
    Cinema_Listing = 'cinemas',
    Showtimes_By_Cinema = 'cinemas/showtime-by-cinemas',
    Fast_Ticket = 'fast-ticket',
    My_Ticket_Invoice = 'ticket-invoice',
    Sign_Up_OTP = 'sign-up/otp',
    Forget_Password_OTP = 'forgot-password/otp',
    First_Time_Login_OTP = 'first-time-login/otp',
    My_Ticket_Listing = 'profile/my-ticket',
    My_GSCoins_Transaction = 'profile/my-gscoins',
    Edit_Profile = 'profile/edit-profile',
    My_Active_Rewards_Listing = 'profile/my-rewards',
    My_Past_Rewards_Listing = 'profile/my-rewards/my-past-rewards',
    My_Active_Rewards_Details = 'profile/my-rewards-details',
    My_Past_Rewards_Details = 'profile/my-rewards-past-details',
    Rewards_Centre_Listing = 'profile/rewards-centre',
    Rewards_Centre_Details = 'profile/rewards-centre-details',
    Referral = 'profile/referrals',
    Favourite_Cinema_Listing = 'profile/favourite',
    Message_Centre = 'profile/message',
    Language = 'profile/language',
    Change_Password = 'profile/change-password',
    Delete_Account = 'profile/delete-account',
    Profile = 'profile',
    Hall_Booking_Listing = 'hall-booking',
    Hall_Booking_Cinema = 'hall-booking/showtime-by-cinemas',
    Hall_Booking_Seat_Selection = 'hall-booking/seat-selection',
    Hall_Booking_Confirm_Ticket_Type = 'hall-booking/confirm-ticket-type',
    Hall_Booking_E_Combo = 'hall-booking/e-combo',
    Hall_Booking_Aurum_E_Combo = 'hall-booking/aurum-e-combo',
    Hall_Booking_Review_Summary = 'hall-booking/review-summary',
    E_Invoice = 'einvoice'
}
