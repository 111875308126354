<mat-toolbar
    class="h-[3.125rem] md:h-[4.375rem] min-h-0 bg-gsc-grey-dark sticky z-50 text-sm lg:text-base font-normal text-white font-montRegular text-[14px] shadow-[0_10px_14px_0_rgba(0,0,0,0.4)]">
    <mat-toolbar-row class="gsc-container nav-bar-web flex-row gap-x-3 justify-between items-center w-full">
        <div class="grow">
            <a title="home" class="block cursor-pointer gsc-icon-lg w-[6.813rem] bg-gsc-icon-app" [href]="[GSC_WEBSITE_URL]"></a>
        </div>

        <div class="flex flex-row gap-x-3 sm:gap-x-4 md:gap-x-6 h-full items-center">
            <a *ngFor="let menuItem of menu" class="py my-1 text-sm"
                [ngClass]="menuService.isActiveMenu(menuItem) ? 'active' : 'hover:text-gsc-main-yellow hover:-translate-y-[2px]'"
                role="button" [routerLink]="menuItem.url" class="mt-2">
                <p translate>{{ menuItem.name }}</p>
                <div class="active-line"></div>
            </a>
            <div class="flex flex-row items-center gap-x-2 text-gsc-main-yellow cursor-pointer" [class.hidden]="!accountService.isAuthenticated()" [routerLink]="['/'+ PROFILE_URL, MY_TICKET_URL]">
                <div (mouseover)="me.openMenu()">
                    <div
                        [matMenuTriggerFor]="accountMenu" #me="matMenuTrigger"
                        class="cursor-pointer gsc-icon-md leading-[30px] bg-black rounded-full border border-gsc-main-yellow text-center text-xs">
                        <div>{{ accountService.getUserInitial().toUpperCase()}}</div>
                    </div>
                </div>

                <p class="hidden lg:block max-w-[12rem] truncate" (mouseover)="me.openMenu()">{{ userName }}</p>
                <mat-menu #accountMenu="matMenu" class="account-menu mt-3" [hasBackdrop]="false">
                    <div class="mx-auto flex items-center gap-x-2 cursor-pointer px-3 py-2" (click)="onLogoutClick()" (mouseleave)="me.closeMenu()">
                        <div class="gsc-icon-sm bg-gsc-icon-logout"></div>
                        <button class="text-center text-xs text-[#FF0000] font-poppinsRegular" translate>Log Out</button>
                    </div>
                </mat-menu>
            </div>
            <div [class.hidden]="accountService.isAuthenticated()" class="flex items-center gap-x-2">
                <button class="hidden lg:block bg-gsc-main-yellow rounded-md text-sm font-montRegular font-semibold py-1 px-4 text-black h-[1.875rem]"
                    [routerLink]="[LOGIN_URL]" translate>Login / Sign In</button>
            </div>
        </div>
    </mat-toolbar-row>

    <mat-toolbar-row class="gsc-container nav-bar-mobile flex-row justify-between items-center w-full">
        <div>
            <a title="home" class="block gsc-icon-lg w-[6rem] bg-gsc-icon-app" (click)="closeMobileNav()" [href]="[GSC_WEBSITE_URL]"></a>
        </div>
        <div class="flex items-center gap-x-[1.136rem]">
            <div class="cursor-pointer flex flex-row items-center gap-x-2 text-gsc-main-yellow cursor-pointer" [class.hidden]="!accountService.isAuthenticated()" [routerLink]="['/'+ PROFILE_URL]" (click)="closeMobileNav()">
                <div
                    (click)="closeMobileNav()"
                class="gsc-icon-md leading-[30px] bg-black rounded-full border border-gsc-main-yellow text-center text-xs">
                    {{ userName.slice(0,1).toUpperCase() }}
                </div>
                <p class="block lg:hidden truncate max-w-[6rem] xs:max-w-[7.5rem] md:max-w-xs">{{ userName }}</p>
            </div>
            <button class="btn agmo-main-yellow-btn w-fit rounded-md min-h-0 px-[0.625rem] py-[0.281rem] text-xs" [class.hidden]="accountService.isAuthenticated()" [routerLink]="[LOGIN_URL]" translate>Login / Sign In</button>
            <mat-icon (click)="onToggleNav()" class="cursor-pointer">menu</mat-icon>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
